<template>
  <section class="lh-cancelled">
    <div class="lh-Wrapper">
      <div class="lh-Wrapper-box lh-Wrapper-box--small">
        <div class="lh-Wrapper-boxContent">
          <ul class="lh-Breadcrumb">
            <li class="lh-Breadcrumb-item">
              <router-link class="lh-Breadcrumb-link" to="/"
                >Accueil</router-link
              >
            </li>
            <li class="lh-Breadcrumb-item">
              <a class="lh-Breadcrumb-link">Déjà vendue</a>
            </li>
          </ul>
          <div class="lh-Contact-form">
            <h1 class="lh-Form-title">Oups, cette LHight est déjà vendue.</h1>
            <p>
              Désolé, la LHight que vous avez tenté d'acheter est déjà vendue...
              <br />
              Veuillez en choisir une autre ou revenez plus tard ;)
            </p>
            <router-link class="lh-Button" to="/boutique"
              >Retour à la boutique</router-link
            >
          </div>
        </div>
      </div>
      <div class="lh-Wrapper-box lh-Wrapper-box--big lh-Wrapper-box--dark">
        <div class="lh-404-bg"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "soldout",
  data: () => {
    return {};
  },
};
</script>
<style lang="scss">
@import "@/components/soldout/_index.scss";
</style>
