<template>
  <Soldout />
</template>

<script>
import Soldout from "@/components/soldout/Soldout.vue";

export default {
  name: "soldout",
  components: {
    Soldout,
  },
};
</script>
